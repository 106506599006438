$('.apply-link a').on('click', function() {
    $('html, body').animate({
        scrollTop: $("#applicationFormWrapper").offset().top -60,
    }, 500);
});


$('.job-contact-form .btn-toggle').on('click', function (){
    $('.toggle-icon').toggleClass('open');
    $('.job-contact-form .btn-text').toggleClass('d-none')
    $('.job-contact-form .btn-text-close').toggleClass('d-none')
});
