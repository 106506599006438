$(function() {
    console.log('Tracking js loaded');

    /* -- Google Analytics Event Tracking -- */
    $("a[href^='tel:']").on('click', function (e){
        var eCategory = 'Klick Telefonnummer',
            eAction = $(this).attr('href').replace('tel:', ''),
            eLabel = window.location.href;

        console.log("----- gtag Event -----");
        console.log("Kategorie", eCategory);
        console.log("Action", eAction);
        console.log("Label", eLabel);

        if (typeof gtag === 'function') {
            gtag('event', eAction, {
                'event_category' : eCategory,
                'event_label' : eLabel
            });
            console.log("----- Event sent -----");
        }
        console.log("----- ---------- -----");
    });

    $("a[href^='mailto:']:not(.apply-to-link)").on('click', function (e){
        var eCategory = 'Klick E-Mail',
            eAction = $(this).attr('href').replace('mailto:', ''),
            eLabel = window.location.href;

        console.log("----- gtag Event -----");
        console.log("Kategorie", eCategory);
        console.log("Action", eAction);
        console.log("Label", eLabel);


        if (typeof gtag === 'function') {
            gtag('event', eAction, {
                'event_category' : eCategory,
                'event_label' : eLabel
            });
            console.log("----- Event sent -----");
        }

        console.log("----- ---------- -----");
    });

    $("a.apply-to-link").on('click', function (e){
        var eCategory = 'Klick Bewerbungslink',
            eAction = $(this).attr('href').replace('mailto:', ''),
            eLabel = window.location.href;

        console.log("----- gtag Event -----");
        console.log("Kategorie", eCategory);
        console.log("Action", eAction);
        console.log("Label", eLabel);


        if (typeof gtag === 'function') {
            gtag('event', eAction, {
                'event_category' : eCategory,
                'event_label' : eLabel
            });
            console.log("----- Event sent -----");
        }

        console.log("----- ---------- -----");
    });
});
